<template>
  <div
    :id="id" class="c-modal modal" tabindex="0"
    style="z-index: 1003; display: none; opacity: 0; top: 4%; transform: scaleX(0.8) scaleY(0.8)"
  >
    <div class='add-coupon-container'>
      <div class='text-2xl-bold'>Cupón</div>
      <div style='padding: 32px 0'>
        <InputText
          placeholder='Nombre de cupón'
          v-model='coupon'
          missingErrorMessage='Ingresa cupón.'
          :showEmptyInputError='tryToSubmit && !coupon'
        />
      </div>
      <div class="c-modal__footer-buttons c-nav m-1">
        <ButtonDefinitive
          label="Aplicar"
          @click="save"
        />
        <ButtonDefinitive
          variant='tertiary'
          label="Cancelar"
          @click='handleCloseModal'
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'
import InputText from '@/components/Form/InputText.vue'

export default {
  name: "ModalCoupon",
  components: { ButtonDefinitive, InputText },
  props: {
    id: String,
  },
  data() {
    return {
      coupon: '',
      tryToSubmit: false
    };
  },
  methods: {
    async save() {
      this.tryToSubmit = true
      if (!this.coupon) return

      this.showLoading()

      if (this.serviceType === 1 || this.serviceType === 3) {
        const payload = {
          coupon: this.coupon,
          serviceTypeId: this.serviceType,
          weight:
            this.serviceType === 3
              ? this.$store.getters.COMPRATRAE_ORDER_WEIGHT
              : null,
        };
        await this.$store
          .dispatch("GET_COUPON", payload)
          .then((success) => {
            this.$swal.close();
            if (success.status === 200) {
              this.$emit("getSelectedCoupon", success.data.result);
              this.coupon = "";
              this.closeModal(this.id);
            } else {
              this.showError(success.data.message);
              this.$emit("getSelectedCoupon", null);
            }
          })
          .catch((error) => {
            const msg = error?.data?.message || "Ocurrio un error.";
            this.showError(msg);
            this.$emit("getSelectedCoupon", null);
          });
      } else {
        let lstDestination = null;
        if (this.serviceType === 2) {
          lstDestination = this.$store.getters.PROGRAMMED_ORDER_DESTINATIONN;
        } else {
          lstDestination = this.$store.getters.SCHARFFPOINT_ORDER_DESTINATION;
        }
        const lstPeso = lstDestination.map((el) => el.packageSize.weight / 10);
        const payload = {
          coupon: this.coupon,
          serviceTypeId: this.serviceType,
          cantOrders: lstDestination.length,
          lstPeso,
        };
        await this.$store
          .dispatch("GET_COUPON_MULTI", payload)
          .then((success) => {
            this.$swal.close();
            if (success.status === 200) {
              this.$emit("getSelectedCoupon", success.data.result);
              this.coupon = "";
              this.closeModal(this.id);
            } else {
              this.showError(success.data.message);
              this.$emit("getSelectedCoupon", null);
            }
          })
          .catch((error) => {
            const msg = error?.data?.message || "Ocurrio un error.";
            this.showError(msg);
            this.$emit("getSelectedCoupon", null);
          });
      }
    },
    handleCloseModal() {
      this.closeModal(this.id);
    }
  },
  computed: {
    serviceType() {
      return this.$store.getters.CURRENT_SERVICE;
    },
  },
};
</script>

<style lang='scss' scoped>
.add-coupon-container {
  padding: 32px 20px;
}
</style>

<!-- eslint-disable max-len -->
<template>
  <div style='display: flex; flex-direction: column'>
    <div class='modal c-modal--mobile-full-screen' :id='id'>
      <Addresses
        :isOrdering='true'
        @handleGoBackButton='closeModal'
        @getFavoriteAddress='sendAddress'
      />
    </div>
  </div>
</template>

<script>
import Addresses from '../../views/Addresses.vue'

export default {
  components: {
    Addresses
  },
  props: {
    id: String,
  },
  methods: {
    closeModal() {
      const modalContactFavorite = document.querySelector(`#${this.id}`)
      if (modalContactFavorite) {
        const instanceModalContactFavorite = M.Modal.getInstance(modalContactFavorite)
        instanceModalContactFavorite.close()
      }
    },
    sendAddress(addressData) {
      this.$emit('getFavoriteAddress', addressData)
      this.closeModal()
    },
  },
  mounted() {
    this.$nextTick(async () => { })
  },
}
</script>


<style lang="scss" scoped>


  .c-modal {    
    &--mobile-full-screen {      
      @media (min-width: 767px) {
        border-radius: 15px !important;
      }
      @media screen and (max-width: 768px) {
        margin: 0;
        top: 0;
        max-height: 100% !important;
        height: 100% !important;
        max-width: 100%;
        width: 100%;
        overflow-y: auto;
      }
    }
  }
</style>
<!-- eslint-disable max-len -->
<template>
  <div id="modalRestriction" class="modal c-modal p-0">
    <div class="c-content--modalRestriction p-0">
      <div class="c-content--img center-align">
        <div class="c-content--modalBannerCurved pt-1 pb-1">
          <div class="title">
            Restricciones
            <div class="close modal-close">
              <img src="~@/assets/img/icon/icon-closex.svg" alt />
            </div>
          </div>
        </div>
        <img
          src="~@/assets/img/service-aerolina.svg"
          alt
          width="400"
          height="auto"
        />
      </div>
      <div class="c-section--bannerCircle c-section--bannerCircle__plane"></div>
      <div class="scroll-text c-scrollbar">
        <app-notification-modal
          :attributes="{
            visible: true,
            class: 'c-notification c-notification--alert-notification white',
            title: 'Mercancía prohibida:',
            text: 'Este tipo de productos no pueden ingresar al Perú por restricciones gubernamentales.',
            link: 'https://saio.holascharff.com/Files/mercaderia-prohibida.pdf',
            linkText: 'Ver lista',
          }"
        ></app-notification-modal>




        <app-notification-modal
          :attributes="{
            visible: true,
            class: 'c-notification c-notification--alert-notification white',
            title: 'Mercancía Restringida:',
            text: 'Este tipo de productos deben cumplir determinados requisitos en su control y fiscalización para poder ingresar al país.',
            link: 'https://saio.holascharff.com/Files/mercaderia-restringida.pdf',
            linkText: 'Ver lista',
          }"
        ></app-notification-modal>
      </div>
    </div>
    <div class="buttonRestriccion c-nav--floating-footer-sticky">
      <a
        href="javascript:void(0)"
        class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect m-2 modal-close"
        >Cerrar</a
      >
    </div>
  </div>
</template>
<script>
import AppNotificationModal from "../General/AppNotificationModal.vue";

export default {
  components: {
    AppNotificationModal,
  },
};
</script>
<style lang=""></style>

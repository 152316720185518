<template>
  <div class="modal c-modal" :id="id">
    <div class="c-modal--directions-title">
      <h2>Contacto</h2>
    </div>
    <div class="c-modal--directions-body">
      <div class="c-input input-field">
        <input type="text" value="" v-model="name" />
        <label for="">* Nombre y apellidos</label>
      </div>
    </div>
    <div class="c-modal--directions-body">
      <div class="c-input input-field">
        <input
          type="text"
          @keypress="onlyNumbers"
          v-model="phone"
          @paste="removeLettersOnPaste2($event, 'phone')"
        />
        <label for="">* Número celular</label>
      </div>
    </div>
    <div class="c-modal--directions-body">
      <div class="c-input input-field">
        <input type="text" value="" v-model="email" />
        <label for="">Correo Electrónico(opcional)</label>
      </div>
    </div>
    <div class="c-modal--directions-body">
      <label class="c-radio-buttons c-rb-item-type-doc"
        ><input
          v-model="documentType"
          value="DNI"
          name="option-shipping-type"
          type="radio"
          class="c-option-shipping-type"
          :checked="this.documentType === 'DNI'"
        />
        <span>DNI</span>
      </label>

      <label class="c-radio-buttons c-rb-item-type-doc"
        ><input
          v-model="documentType"
          value="Carné de Extranjería"
          name="option-shipping-type"
          type="radio"
          class="c-option-shipping-type"
          :checked="this.documentType === 'Carné de Extranjería'"
        />
        <span>Carnet de Extranjería</span>
      </label>
    </div>
    <div class="c-modal--directions-body">
      <div class="c-input input-field">
        <input
          type="text"
          value=""
          v-model="documentNumber"
          @keypress="onlyNumbers"
        />
        <label for="">Número de documento(opcional)</label>
      </div>
    </div>
    <article
      class="c-grid--services__item c-section--dateContent pt-2"
      v-if="disableFavoriteSwitch === 'false'"
    >
      <div
        class="c-section--content-check justify-content-around align-items-center"
      >
        <p class="font-weight-light">Guardarlo como favorito</p>
        <div class="switch">
          <label
            ><input type="checkbox" v-model="favorite" />
            <span class="lever"></span>
          </label>
        </div>
      </div>
    </article>
    <div class="c-modal__footer-buttons c-nav m-1">
      <button
        v-on:click="sendData"
        class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
      >
        Guardar
      </button>
      <!-- <button
        id=""
        class="c-button c-button--width-fuid c-button--flat c-button--mobile-small waves-effect modal-close"
      >
        Cancelar
      </button> -->
      <button
        class="c-button c-button--secondary c-button--width-fuid c-button--mobile-small waves-effect modal-close"
        id="c-btn-show-contact-1"
        @click="closeModal"
      >
        {{ contact ? "Cerrar" : "Cancelar" }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalContact",
  props: {
    id: String,
    disableFavoriteSwitch: {
      type: String,
      default: "false",
    },
    contact: {
      default: null,
    },
  },
  data() {
    return {
      name: this.contact?.nombreContacto || "",
      phone: this.contact?.telefonoContacto || "",
      email: this.contact?.correoContacto || "",
      documentNumber: this.contact?.nroDocumentoContacto || "",
      documentType: this.contact?.tipoDocumento || "DNI",
      favorite: false,
    };
  },
  computed: {
    isNewContact() {
      return !this.contact;
    },
  },
  watch: {
    contact: {
      handler() {
        this.name = this.contact?.nombreContacto || "";
        this.phone = this.contact?.telefonoContacto || "";
        this.email = this.contact?.correoContacto || "";
        this.documentNumber = this.contact?.nroDocumentoContacto || "";
        this.documentType = this.contact?.tipoDocumento || "DNI";
      },
      deep: true,
    },
    // phone() {
    //   this.$nextTick(() => {
    //     this.phone = this.phone.replace(/[^0-9]/g, "");
    //   });
    // },
  },
  methods: {
    sendData() {
      try {
        let isValid = true;
        let errorMsg = "";
        const contact = {
          contactId: this.contact ? this.contact.contactId : null,
          nombreContacto: this.name,
          telefonoContacto: this.phone,
          correoContacto: this.email,
          nroDocumentoContacto: this.documentNumber,
          documentType:
            this.documentNumber.length > 0 ? this.documentType : null,
          favorite: this.favorite,
        };

        if (!this.name) {
          errorMsg += "El campo nombre es obligatorio.<br/>";
          isValid = false;
        }

        if (this.name && this.name.length > 100) {
          errorMsg +=
            "El campo nombre no debe superar los 100 caracteres.<br/>";
          isValid = false;
        }

        if (!this.phone) {
          errorMsg += "El campo número de celular es obligatorio.<br/>";
          isValid = false;
        }

        if (this.phone && this.phone.length !== 9) {
          errorMsg += "El campo número de celular debe poseer 9 digitos.<br/>";
          isValid = false;
        }

        // if (!this.email) {
        //   errorMsg += "El campo correo electrónico es obligatorio.<br/>";
        //   isValid = false;
        // }

        // if (!this.validateEmail(this.email)) {
        //   errorMsg += "El campo correo electrónico debe ser válido.<br/>";
        //   isValid = false;
        // }

        if (this.documentNumber) {
          if (!this.documentType) {
            errorMsg += "Selecciona el tipo de documento.<br/>";
            isValid = false;
          } else if (
            this.documentType === "DNI" &&
            this.documentNumber.length !== 8
          ) {
            errorMsg +=
              "El campo número de documento debe contener 8 digitos.<br/>";
            isValid = false;
          } else if (this.documentNumber.length > 20) {
            errorMsg +=
              "El campo número de documento debe contener máximo 20 digitos.<br/>";
            isValid = false;
          }
        }

        if (isValid === false) {
          this.showError(errorMsg);
        } else {
          if (this.isNewContact) {
            this.$emit("getAddContactData", contact);
          } else {
            this.$emit("saveChangesContact", contact);
          }
          this.clearData();
          this.closeModal(this.id);
        }
      } catch (error) {
        console.error(error);
      }
    },
    clearData() {
      this.name = "";
      this.phone = "";
      this.email = "";
      this.documentNumber = "";
      this.documentType = "DNI";
      this.favorite = false;
    },
  },
  mounted() {
    this.$nextTick(async () => {});
    this.$eventBus.$on("modalContactClearData", () => {
      this.clearData();
    });
  },
  created() {
    this.$nextTick(async () => {});
  },
};
</script>

<style></style>

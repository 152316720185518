<template>
  <div
    id="modalCalculator"
    class="modal c-modal c-modal--calculator p-0"
    tabindex="0"
    style="
      z-index: 1003;
      display: none;
      opacity: 0;
      top: 4%;
      transform: scaleX(0.8) scaleY(0.8);
    "
  >
    <div class="p-0">
      <!-- <div class="c-modal--calculator__close modal-close" v-if="showPMQuoter"></div> -->
      <ct-quoter v-show="showCTQuoter" />
      <pm-quoter v-show="showPMQuoter" />
    </div>
  </div>
</template>
<script>
import CtQuoter from "../CompraTrae/Quoter.vue";
import PmQuoter from "../Programmed/Quoter.vue";

export default {
  components: {
    CtQuoter,
    PmQuoter,
  },
  computed: {
    showCTQuoter() {
      return this.$store.getters.STYLE_SHOW_CT_QUOTER;
    },
    showPMQuoter() {
      return this.$store.getters.STYLE_SHOW_PM_QUOTER;
    },
  },
};
</script>
<style lang=""></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal c-modal p-0",attrs:{"id":"modalRestriction"}},[_c('div',{staticClass:"c-content--modalRestriction p-0"},[_vm._m(0),_c('div',{staticClass:"c-section--bannerCircle c-section--bannerCircle__plane"}),_c('div',{staticClass:"scroll-text c-scrollbar"},[_c('app-notification-modal',{attrs:{"attributes":{
          visible: true,
          class: 'c-notification c-notification--alert-notification white',
          title: 'Mercancía prohibida:',
          text: 'Este tipo de productos no pueden ingresar al Perú por restricciones gubernamentales.',
          link: 'https://saio.holascharff.com/Files/mercaderia-prohibida.pdf',
          linkText: 'Ver lista',
        }}}),_c('app-notification-modal',{attrs:{"attributes":{
          visible: true,
          class: 'c-notification c-notification--alert-notification white',
          title: 'Mercancía Restringida:',
          text: 'Este tipo de productos deben cumplir determinados requisitos en su control y fiscalización para poder ingresar al país.',
          link: 'https://saio.holascharff.com/Files/mercaderia-restringida.pdf',
          linkText: 'Ver lista',
        }}})],1)]),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-content--img center-align"},[_c('div',{staticClass:"c-content--modalBannerCurved pt-1 pb-1"},[_c('div',{staticClass:"title"},[_vm._v(" Restricciones "),_c('div',{staticClass:"close modal-close"},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-closex.svg"),"alt":""}})])])]),_c('img',{attrs:{"src":require("@/assets/img/service-aerolina.svg"),"alt":"","width":"400","height":"auto"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buttonRestriccion c-nav--floating-footer-sticky"},[_c('a',{staticClass:"c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect m-2 modal-close",attrs:{"href":"javascript:void(0)"}},[_vm._v("Cerrar")])])}]

export { render, staticRenderFns }
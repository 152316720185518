<!-- eslint-disable max-len -->
<template>
  <div class="c-popup-slide c-popup-slide--right" :id="id">
    <div class="c-popup-slide__backdrop c-btn-popup-slide-close" :data-popup-id="id"></div>
    <div class="c-popup-slide__container px-0">
      <!-- @start Header-->
      <div class="c-popup-slide__header">
        <div class="c-popup-slide__content">
          <h3>Agregar paquete</h3>
        </div>
        <div class="close c-popup-slide__close">
          <img class="c-btn-popup-slide-close" src="~@/assets/img/icon/icon-closex.svg" :data-popup-id="id" />
        </div>
      </div>
      <!-- @end Header-->
      <!-- @start content-->
      <div class="c-popup-slide__body">
        <div class="c-popup-slide__body__container">
          <div class="c-popup-slide__content c-scrollbar">
            <div class="content-information-the-package">
              <!-- @start location delivery-->
              <div class="content-package-delivery">
                <h4>1) Indicar lugar de entrega</h4>
                <input-district id="programmed-step-3-modal-pop" text="Buscar distrito"
                  @getDistrictId="setDestinationDistrict" @input="districtId = 0" serviceType="2"
                  @focus="isDestinationFocused = false"></input-district>

                <div class="c-input input-field curso-pointer">
                <!-- <vue-google-autocomplete
                    ref="destinationAddress"
                    id="c-focus-open-modal-search-direction-destination"
                    class="curso-pointer"
                    placeholder
                    v-on:placechanged="getDestinationAddressData"
                    country="pe"
                    v-on:focus="isDestinationFocused = true"
                    ></vue-google-autocomplete> -->
                  <input ref="destinationAddress" type="text" v-model="destinationAddress.description"
                    @focus="isDestinationFocused = true" max="150" maxlength="150" />

                  <label for>Dirección</label>
                <!-- <d iv
                    v-if="false"
                    id="c-icon-favorite-1"
                    class="c-input--icon btnOpenModalDirecitionClass"
                    v-on:click="AddAddressFavorite('destination', null)"
                  >
                    <img
                      v-if="destinationAddress.favorite === true"
                      src="~@/assets/img/icon/icon-estrella-on.svg"
                      alt="favorite"
                      loading="lazy"
                      class="btn-open-modal-direction"
                    />
                    <img
                      v-else
                      src="~@/assets/img/icon/icon-estrella-set.svg"
                      alt="favorite"
                      loading="lazy"
                      class="btn-open-modal-direction"
                    />
                    </div> -->
                </div>
                <div class="c-input input-field mb-2">
                  <input type="text" v-model="addressDetail"
                    placeholder="Acá puedes colocar todo el detalle de la dirección, incluyendo MZ o Lote"
                    @focus="isDestinationFocused = false" max="299" maxlength="299" />
                  <label for class="active">Referencia</label>
                </div>
                <favorite-address id="favoritePackageAddress" v-show="isDestinationFocused === true"
                  @getSelectedAddress="getDestinationSelectedAddress" modalId="modal-contact-destination" />
              </div>
              <div class="content-package-delivery">
                <h4>2) Datos de quien recibe</h4>
                <article v-if="DestinationContact === null" id="btnModalContactFavorite"
                  class="c-grid--services__item pt-0 pb-0">
                  <a @click="openContactModal('destination')">
                    <div id="" class="card c-card c-card--service-box d-flex p-1">
                      <div class="card-image">
                        <div class="card-title color-text-black">
                          <span class="card-sub-title color-text-black">Escoge tu</span><span
                            class="card-option">contacto</span>
                        </div>
                      </div>
                      <img src="@/assets/img/service-point-sharff-3.svg" class="translatex" />
                    </div>
                  </a>
                </article>
                <div v-if="DestinationContact !== null" id="c-contact-send-2"
                  class="c-btn-contact-click c-content--spacing-block">
                  <div class="collection c-collection c-collection--not-hover c-collection--card">
                    <div class="collection-item collection-item--arrow-next collection-item--icon">
                      <a @click="editDestinationContact()" class="collection-item--icon collection-item--icon--user">
                        <div class="c-text-medium font-weight-bold">
                          {{ DestinationContact.name }}
                        </div>
                        <div class="c-text-medium">
                          {{ DestinationContact.phone }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-package-tab-result">
                <h4>3) Tamaño del paquete</h4>
              <!-- <article class="c-grid--services__item">
                  <div class="c-section--content-package">
                    <ul
                      id="navList"
                      class="navList c-nav--tab-d-c d-flex justify-content-between m-0 w-100 tabs h-100 h-100 c-bg-none"
                    >
                      <li
                        v-for="item in listPackageSize"
                        v-bind:key="item.id"
                        class="c-tab-button item-package tab h-100 line-height-initial"
                      >
                        <a
                          :href="'#' + item.id"
                          v-on:click="setTypePackageSize(item)"
                          v-bind:class="{
                            active: item.id === packageSize.type,
                            'active-package': item.id === packageSize.type,
                          }"
                          class="item-package-proof w-100 color-black"
                        >
                          <img
                            v-if="item.id === 'Pequeno'"
                            src="~@/assets/img/icon/icon-small.svg"
                            alt=""
                          />
                          <img
                            v-if="item.id === 'Mediano'"
                            src="~@/assets/img/icon/icon-medium.svg"
                            alt=""
                          />
                          <img
                            v-if="item.id === 'Grande'"
                            src="~@/assets/img/icon/icon-grand.svg"
                            alt=""
                          />
                          <img
                            v-if="item.id === 'Personalizado'"
                            src="~@/assets/img/icon/icon-small.svg"
                            alt=""
                          />
                          <p>{{ item.description }}</p></a
                        >
                      </li>
                      <li
                        class="indicator"
                        style="left: 0px; right: 419px"
                      ></li>
                    </ul>
                  </div>
                  </article> -->
              </div>
              <div class="content-package-information-result">
                <div class="c-content--box-shadow mb-1">
                  <article class="c-grid--services__item c-section--dateContent">
                    <div>
                      <div class="row m-0">
                        <div class="col s4">
                          <div class="c-input input-field">
                            <input type="text" v-model="packageSize.height" @keypress="isNumber($event)" maxlength="4" />
                            <label for="" class="active">Alto</label>
                          </div>
                        </div>
                        <div class="col s4">
                          <div class="c-input input-field">
                            <input type="text" v-bind:value="packageSize.width" @keypress="isNumber($event)" maxlength="4"
                              v-on:input="
                                packageSize.width = $event.target.value
                              " />
                            <label for="" class="active">Ancho</label>
                          </div>
                        </div>
                        <div class="col s4">
                          <div class="c-input input-field">
                            <input type="text" v-model="packageSize.lengths" maxlength="4" @keypress="isNumber($event)" />
                            <label for="" class="active">Largo</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="c-content--range d-flex justify-content-between">
                      <div class="c-modal--directions-body content w-40">
                        <label for="">Peso (kg)</label>
                        <div class="c-input input-field">
                          <input type="number" :min="1" :max="999" v-model="packageSize.weight" />
                          <label for class="font-weight-light active">kg</label>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article class="c-grid--services__item c-section--dateContent">
                    <div class="c-modal--directions-body">
                      <div class="c-input input-field">
                        <input type="text" v-model="description" />
                        <label for="" class="active">Descripción del producto a enviar</label>
                      </div>
                    </div>
                    <div class="c-modal--directions-body">
                      <div class="c-input input-field">
                        <input type="text" v-model="productValue" @keypress="isNumber($event)" />
                        <label for="" class="active">Valor del producto (S/)</label>
                      </div>
                    </div>
                  </article>
                </div>
                <article class="c-grid--services__item c-section--dateContent">
                  <div class="c-section--incognito mt-2" data-position="top"
                    data-tooltip="Recuerda que el seguro de este servicio cubre hasta S/1000. Te recomendamos enviar productos que tengan hasta ese valor como máximo.">
                    <img src="~@/assets/img/icon/icon-exclamation-black.svg" alt="" class="mr-1" />Tenemos garantía de
                    hasta
                    <span class="font-weight-bold">S/1000 soles</span>
                  </div>
                  <div v-if="amountMax" class="c-section--incognito icongnito-warning">
                    <img src="~@/assets/img/icon/icon-exclamation-red.svg" alt="" class="mr-1" />Haz excedido el monto
                    permitido, de continuar aceptas
                    nuestras restricciones
                  </div>
                </article>
                <article class="c-grid--services__item c-section--dateContent">
                  <div class="m-0 d-flex justify-content-center">
                    <a id="btnOpenModalRestriction"
                      class="c-button c-button--five c-button--mobile-small waves-effect restriccion">
                      Ver restricciones<img src="~@/assets/img/icon/icon-danger.svg" alt="" /></a>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-popup-slide__footer">
        <div class="c-nav c-nav--floating-footer-sticky__spaceButtonPair">
          <a :data-popup-id="id" @click="saveDestinationAddress()"
            class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect boton-guardar">Guardar</a>
          <button :data-popup-id="id"
            class="c-button c-button--width-fuid c-button--mobile-small waves-effect c-button--flat c-btn-popup-slide-close">
            Salir
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import InputDistrict from "../General/InputDistrict.vue";
import FavoriteAddress from "../General/FavoriteAddress.vue";
import Map from "../../commons/mixins/maps";
import { getProgrammedFare } from "../../services/fare";
export default {
  components: {
    InputDistrict,
    FavoriteAddress,
  },
  name: "ModalProgrammedPackage",
  props: {
    id: String,
  },
  data() {
    return {
      map: null,
      isDestinationFocused: false,
      listPackageSize: [
        {
          id: "Personalizado",
          title: "Paquete Personalizado",
          description: "Perzonalizado",
          imgUrl: "~@/assets/img/icon/icon-other.svg",
          packageSize: {
            height: 0, // Altura
            lengths: 0, // Largo
            width: 0, // Ancho
            weight: 0, // peso
            maxWeight: 1000,
          },
        },
      ],
      packageSize: {
        height: 0, // Altura
        lengths: 0, // Largo
        width: 0, // Ancho
        type: "Personalizado",
        weight: 0,
        maxWeight: 1000, // Peso
      },
      defaultPackageSize: {
        height: 0, // Altura
        lengths: 0, // Largo
        width: 0, // Ancho
        type: "Personalizado",
        weight: 0,
        maxWeight: 1000, // Peso
      },
      description: "",
      productValue: 0,
      destinationAddress: {
        description: "",
        lat: null,
        lng: null,
        favorite: false,
      },
      districtId: 0,
      addressDetail: "",
    };
  },
  computed: {
    lstDestination() {
      return this.$store.getters.PROGRAMMED_ORDER_DESTINATIONN;
    },
    DestinationContact() {
      return this.$store.getters.GENERAL_DESTINATION_CONTACT;
    },
    origin() {
      return this.$store.getters.PROGRAMMED_ORDER_ORIGIN;
    },
    amountMax() {
      return this.productValue > 1000;
    },
  },
  watch: {
    productValue() {
      if (this.productValue > 1000) {
        this.productValue = 1000;
      }
    },
    // eslint-disable-next-line func-names
    "packageSize.weight": function () {
      if (this.packageSize.weight > 1000) {
        this.packageSize.weight = "1000";
      }
    },
    // eslint-disable-next-line func-names
    "packageSize.lengths": function () {
      if (this.packageSize.lengths > 150) {
        this.packageSize.lengths = "150";
      }
    },
    // eslint-disable-next-line func-names
    "packageSize.height": function () {
      if (this.packageSize.height > 190) {
        this.packageSize.height = "190";
      }
    },
    // eslint-disable-next-line func-names
    "packageSize.width": function () {
      if (this.packageSize.width > 95) {
        this.packageSize.width = "95";
      }
    },
  },
  created(){
    this.$eventBus.$on("programmedClearDataDestinationModal", () => {
      this.clearData();
    });
    this.$eventBus.$on("programmedSetDataDestinationModal", (destination) => {
      this.setData(destination);
    });
  },
  mounted() {
    const elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems);
    this.map = new Map();
    this.map.initMap("mp_pr_type1");
  },
  methods: {
    isNumber(evnt) {
      const evt = evnt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
        return false;
      }
      return true;
    },
    validateAddPackage() {
      if (
        this.packageSize.weight === null ||
        this.packageSize.weight === 0 ||
        this.packageSize.weight === "0"
      ) {
        this.showError("Debe de ingresar el peso del paquete");
        return false;
      }
      if (this.packageSize.weight > this.packageSize.maxWeight) {
        this.showError(
          `El peso máximo para el paquete ${this.packageSize.type} es de ${this.packageSize.maxWeight} kg`
        );
        return false;
      }
      if (this.packageSize.type === "Personalizado") {
        if (
          parseInt(this.packageSize.height, 10) === 0 ||
          this.packageSize.height === ""
        ) {
          this.showError(`Debe ingresar la altura del paquete`);
          return false;
        }
        if (
          parseInt(this.packageSize.width, 10) === 0 ||
          this.packageSize.width === ""
        ) {
          this.showError(`Debe ingresar el ancho del paquete`);
          return false;
        }
        if (
          parseInt(this.packageSize.lengths, 10) === 0 ||
          this.packageSize.lengths === ""
        ) {
          this.showError(`Debe ingresar el largo del paquete`);
          return false;
        }
      }
      if (this.description === null || this.description === "") {
        this.showError("Debe de ingresar la descripcion");
        return false;
      }
      if (this.productValue === null || this.productValue === 0) {
        this.showError("Debe de ingresar el valor del producto");
        return false;
      }
      if (this.destinationAddress.description === "") {
        this.showError("Debe de ingresar la direccion del paquete");
        return false;
      }
      if (this.districtId === 0) {
        this.showError(
          "Debe seleccionar un distrito de las opciones desplegables"
        );
        return false;
      }

      if (this.DestinationContact === null) {
        this.showError("Debe seleccionar un contacto");
        return false;
      }
      return true;
    },
    setModel(origin, destination) {
      const fareRequest = {
        OriginDistrictId: origin.districtId.id,
        DestinationDistrictId: destination.destinationAddress.districtId.id,
      };
      return fareRequest;
    },
    saveDestinationAddress() {
      if (this.validateAddPackage()) {
        const destinationTemp = {
          index: this.$store.getters.PROGRAMMED_INDEX_DESTINATION,
          item: {
            description: this.description,
            destinationAddress: {
              description: this.destinationAddress.description,
              lat: this.destinationAddress.lat,
              lng: this.destinationAddress.lng,
              districtId: this.districtId,
              contact: this.DestinationContact,
              addressDetail: this.addressDetail,
            },
            productValue: this.productValue,
            packageSize: this.packageSize,
            fare: 0,
            discountFare: null,
          },
        };

        const obj = this.setModel(this.origin, destinationTemp.item);

        this.showLoading();


        const responseFare = getProgrammedFare(obj)
          .then((response) => {
            debugger;
            destinationTemp.item.fare = response.data.totalFare;
            const destination = JSON.parse(JSON.stringify(destinationTemp));

            this.$store.dispatch(
              "PROGRAMMED_ORDER_SET_DESTINATION",
              destination
            );

            const sumall = this.lstDestination
              .map((item) => item.fare)
              .reduce((prev, curr) => prev + curr, 0);
            this.$store.dispatch("PROGRAMMED_FARE", sumall);
            this.$swal.close();
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: error,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          });
        // this.$store
        //   .dispatch("PROGRAMMED_CALCULATE_FARE", obj)
        //   .then((response) => {
        //     destinationTemp.item.fare = response.totalFare;
        //     const destination = JSON.parse(JSON.stringify(destinationTemp));

        //     this.$store.dispatch(
        //       "PROGRAMMED_ORDER_SET_DESTINATION",
        //       destination
        //     );

        //     const sumall = this.lstDestination
        //       .map((item) => item.fare)
        //       .reduce((prev, curr) => prev + curr, 0);
        //     this.$store.dispatch("PROGRAMMED_FARE", sumall);
        //     this.$swal.close();
        //   })
        //   .catch((error) => {
        //     this.$swal({
        //       title: "Error!",
        //       text: error,
        //       icon: "error",
        //       confirmButtonText: "Aceptar",
        //     });
        //   });

        const btnPopupSlideClose = document.querySelectorAll(".boton-guardar");
        const popupId = btnPopupSlideClose[0].getAttribute("data-popup-id");
        const popup = document.getElementById(popupId);
        popup.classList.remove("is-active");
      }
    },
    setTypePackageSize(item) {
      if (item.id !== "other") {
        this.packageSize.height = item.packageSize.height;
        this.packageSize.lengths = item.packageSize.lengths;
        this.packageSize.width = item.packageSize.width;
        this.packageSize.type = item.id;
        this.packageSize.maxWeight = item.packageSize.maxWeight;
        if (this.packageSize.weight > item.packageSize.maxWeight) {
          this.packageSize.weight = item.packageSize.maxWeight;
        }
      } else {
        this.packageSize.type = "other";
      }
    },
    AddAddressFavorite(type) {
      if (type === "origin") {
        this.$eventBus.$emit(
          "updateAddressInput",
          this.destinationAddress.description
        );
        this.$store.dispatch("HELPER_SET_ADD_ADDRESS", this.destinationAddress);
        this.destinationAddress.favorite = true;
      }
      const modalAddAddress = document.querySelector("#modal-add-address");
      const instance = M.Modal.init(modalAddAddress, { dismissible: false });
      instance.open();
    },
    editDestinationContact() {
      this.openContactModal("destination");
    },
    openContactModal(type) {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);
            const modalContactFavorite =
              document.querySelector("#modal-contact");
            const instance = M.Modal.init(modalContactFavorite, {
              dismissible: false,
            });
            instance.open();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    setDestinationDistrict(id) {
      this.districtId = id;
    },

    setAddressByGeo(event) {
      const pos = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      const $this = this;
      this.map.geocodeLocation(pos, (result) => {
        $this.destinationAddress.description = result.formatted;
        $this.destinationAddress.lat = result.position.lat;
        $this.destinationAddress.lng = result.position.lng;
        $this.destinationAddress.favorite = false;
        $this.$refs.destinationAddress.update(result.formatted);
        $this.$swal.close();
      });
    },

    getDestinationAddressData(addressData, placeResult) {
      this.destinationAddress.description = placeResult.formatted_address;
      this.destinationAddress.lat = addressData.latitude;
      this.destinationAddress.lng = addressData.longitude;
      this.isDestinationFocused = false;
      const draggedHandler = (event) => {
        const pos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        const $this = this;
        this.map.geocodeLocation(pos, (result) => {
          $this.destinationAddress.description = result.formatted;
          $this.destinationAddress.lat = result.position.lat;
          $this.destinationAddress.lng = result.position.lng;
          $this.destinationAddress.favorite = false;
          $this.$refs.destinationAddress.update(result.formatted);
          $this.$swal.close();
        });
      };
      this.map.setMarker(
        { Lat: addressData.latitude, Lng: addressData.longitude },
        null,
        false,
        draggedHandler
      );
    },
    getDestinationSelectedAddress(favoriteLocation) {
      this.destinationAddress.description = favoriteLocation.address;
      this.destinationAddress.lat = favoriteLocation.location.Lat;
      this.destinationAddress.lng = favoriteLocation.location.Lng;
      this.$refs.destinationAddress.update(favoriteLocation.address);
      this.isDestinationFocused = false;
      const draggedHandler = (event) => {
        const pos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        const $this = this;
        this.map.geocodeLocation(pos, (result) => {
          $this.destinationAddress.description = result.formatted;
          $this.destinationAddress.lat = result.position.lat;
          $this.destinationAddress.lng = result.position.lng;
          $this.destinationAddress.favorite = false;
          $this.$refs.destinationAddress.update(result.formatted);
          $this.$swal.close();
        });
      };
      this.map.setMarker(
        favoriteLocation.location,
        null,
        false,
        draggedHandler
      );
      // this.saveDestinationAddress();
    },
    clearData() {
      this.packageSize = { ...this.defaultPackageSize };
      this.description = null;
      this.productValue = null;
      this.districtId = 0;
      this.addressDetail = "";
      this.$refs.districtId = null;
      this.$refs.destinationAddress = null;
      this.destinationAddress.description = null;
      this.destinationAddress.additionalAddress = null;
      this.isDestinationFocused = false;
      this.map.clearMarkers();
      this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", null);
    },
    setData(destination) {
      this.packageSize = destination.packageSize;
      this.addressDetail = destination.destinationAddress.addressDetail;
      this.description = destination.description;
      this.productValue = destination.productValue;
      this.districtId = destination.destinationAddress.districtId;
      this.destinationAddress.description =
        destination.destinationAddress.description;
      this.destinationAddress.additionalAddress = destination.destinationAddress.additionalAddress;
      this.map.setMarker(
        {
          Lat: destination.destinationAddress.lat,
          Lng: destination.destinationAddress.lng,
        },
        null,
        true
      );
      this.destinationAddress.lat = destination.destinationAddress.lat;
      this.destinationAddress.lng = destination.destinationAddress.lng;
      this.$refs.destinationAddress.update(
        destination.destinationAddress.description
      );
      this.isDestinationFocused = false;
      this.$store.dispatch(
        "HELPER_SET_ADD_DESTINATION_CONTACT",
        destination.destinationAddress.contact
      );
    },
  },
};
</script>

<style></style>

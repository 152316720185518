<!-- eslint-disable max-len -->
<template>
  <div class="modal c-modal p-0" id="modalRestriction">
    <div class="c-content--modalRestriction p-0">
      <div class="c-content--img center-align">
        <div class="c-content--modalBannerCurved pt-1 pb-1">
          <div class="title">
            Restricciones
            <div class="close modal-close">
              <img src="~@/assets/img/icon/icon-closex.svg" alt="" />
            </div>
          </div>
        </div>

        <img
          src="~@/assets/img/service-cart.png"
          alt=""
          width="140"
          height="auto"
        />
      </div>
      <div class="c-section--bannerCircle"></div>
      <div class="scroll-text c-scrollbar">
        <app-notification-modal
          :attributes="{
            visible: true,
            class: 'c-notification c-notification--alert-notification white',
            title: 'Importante:',
            text: 'Se incluirá un cobro adicional de S/ 0.25 por minuto o fracción después de los 5 minutos de espera en el recojo o entrega del pedido. <br> <br> El tiempo de espera máximo desde la llegada del scharffer es de 15 min. <br> <br> P.D: La fracción que se considera como min. adicional es a partir de 10 seg',
          }"
        ></app-notification-modal>
      </div>
    </div>
    <div class="buttonRestriccion c-nav--floating-footer-sticky">
      <a
        href="javascript:void(0)"
        class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect m-2 modal-close"
        >Cerrar</a
      >
    </div>
  </div>
</template>

<script>
import AppNotificationModal from "../General/AppNotificationModal.vue";

export default {
  components: { AppNotificationModal },
  name: "ModalExpressRectrictions",
};
</script>

<style></style>

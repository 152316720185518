<template>
  <div
    :id="id"
    class="modal c-modal"
    tabindex="0"
    style="
      z-index: 1003;
      display: none;
      opacity: 0;
      top: 4%;
      transform: scaleX(0.8) scaleY(0.8);
    "
  >
    <div class="c-section--content-date p-0">
      <ul class="collection c-collection c-collection--menu">
        <li class="collection-item" v-if="false">
          <a
            href="javascript:void(0)"
            @click="setSection('addedit')"
            class="card-icon-content color-black"
            >Editar tarjeta</a
          >
        </li>
        <li class="collection-item">
          <a
            href="javascript:void(0)"
            class="card-icon-content removeCard color-text-red"
            @click="openDeleteCard"
            >Eliminar tarjeta</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionsCard",
  props: {
    id: String,
  },
  methods: {
    openDeleteCard() {
      this.openModal("modalCardDelete");
    },
    setSection(value) {
      this.$store.dispatch("HELPER_SET_CURRENT_CARD_SECTION", value);
    },
  },
  computed: {
    currentCardSection: {
      get() {
        return this.$store.getters.HELPER_CURRENT_CARD_SECTION;
      },
      set(value) {
        this.$store.dispatch("HELPER_SET_CURRENT_CARD_SECTION", value);
      },
    },
  },
};
</script>

<style></style>

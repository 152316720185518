<template>
  <div id="modalProduct" class="c-modal modal">
    <div class="c-modal--directions-title c-modal--icon pt-1 pb-1">
      <h2>Mediano</h2>
      <div class="icon">
        <img src="~@/assets/img/icon/icon-product-w.svg" alt="" />
      </div>
    </div>
    <div class="c-modal--directions-body">
      <div class="c-section--content-date pb-2">
        <ul class="date-info m-0 box-shadow-none">
          <li class="date-info-content">
            <ul class="date-info-content-box">
              <li class="info-black">
                Dimensiones
                <span class="info-light">
                  Alto: 20cm , Ancho:30cm, Largo: 40cm
                </span>
              </li>
            </ul>
          </li>
          <li class="date-info-content">
            <ul class="date-info-content-box">
              <li class="info-black">Producto</li>
              <li class="info-light">S./ 300.00</li>
            </ul>
          </li>
          <li>
            <ul class="date-info-content-box">
              <li class="info-light">
                Lorem Ipsum is simply dummy text of the peeg and typesetting
                industry. Lorem Ipsum has been the industry’s standard.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalProduct",
};
</script>

<style></style>

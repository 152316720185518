<!-- eslint-disable max-len -->
<template>
  <div :id="id" class="modal c-modal">
    <div class="c-modal--directions-title"><h2>Borrar tarjeta</h2></div>
    <div class="c-section--content-send">
      Mensaje de confirmación para eliminar la tarjeta
      {{ currentCardToEdit ? currentCardToEdit.cardNumber : "" }}, confirma para
      continuar.
    </div>
    <div class="c-modal__footer-buttons c-nav m-1">
      <a
        href="javascript:void(0)"
        class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
        @click="confirmDelete"
        >Confirmar para borrar</a
      >
      <button
        class="c-button c-button--width-fuid c-button--flat c-button--mobile-small waves-effect modal-return-option modal-close"
      >
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalCardDelete",
  props: {
    id: String,
  },
  methods: {
    async fetchCards() {
      await this.$store
        .dispatch("GET_CARDS")
        .then((success) => {
          if (success.status === 200) {
            const cards = success.data.result;
            this.$store.dispatch("GENERAL_SET_CARDS", cards);
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    async confirmDelete() {
      let isOk = false;
      this.showLoading();
      await this.$store
        .dispatch("DELETE_CARD", this.currentCardToEdit.cardId)
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            isOk = true;
            this.closeModal(this.id);
            this.closeModal("modalCardvisa");
            this.showOk("Tarjeta eliminada exitosamente!");
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          this.showError(
            error ? error.data.message : "Ocurrio un error interno del sistema."
          );
        });

      if (isOk === true) {
        await this.fetchCards();
      }
    },
  },
  computed: {
    currentCardToEdit() {
      return this.$store.getters.HELPER_CURRENT_CARD_TO_EDIT;
    },
  },
};
</script>

<style></style>

<!-- eslint-disable max-len -->
<template>
  <div class="modal c-modal p-0" id="modalRestriction">
    <div class="c-content--modalRestriction p-0">
      <div class="c-content--img center-align">
        <div class="c-content--modalBannerCurved pt-1 pb-1">
          <div class="title">
            Restricciones
            <div class="close modal-close">
              <img src="~@/assets/img/icon/icon-closex.svg" alt="" />
            </div>
          </div>
        </div>

        <img
          src="~@/assets/img/service-cart.png"
          alt=""
          width="140"
          height="auto"
        />
      </div>
      <div class="c-section--bannerCircle"></div>
      <div class="scroll-text c-scrollbar">
        <app-notification-modal
          :attributes="{
            visible: true,
            class: 'c-notification c-notification--alert-notification white',
            title: 'Importante:',
            text: `Los tiempos de entrega en Lima Metropolitana es de 24 a 48 hrs. Lima periférica es de 48 a 72 hrs y Provincias son de 5 a 7 días hábiles. <br/>
              Solo podrán ser enviadas las mercaderías que cumplan con las siguientes características: <br/>
              - Dimensiones máximas: 95 cm de alto x 190 cm de largo x 150 cm de ancho.<br/>
              - Peso máximo: 100 kg.<br/>
               Prohibido el envío de dinero en efectivo, armas de ningún tipo, obras de arte, alimentos congelados o perecibles, drogas, joyas valoradas y, aparatos tecnológicos, tales como laptops, teléfonos celulares, computadoras de escritorios, televisores y monitores.`,
          }"
        ></app-notification-modal>
      </div>
    </div>
    <div class="buttonRestriccion c-nav--floating-footer-sticky">
      <a
        href="javascript:void(0)"
        class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect m-2 modal-close"
        >Cerrar</a
      >
    </div>
  </div>
</template>

<script>
import AppNotificationModal from "../General/AppNotificationModal.vue";

export default {
  components: { AppNotificationModal },
  name: "ModalProgrammedRectrictions",
};
</script>

<style></style>

<!-- eslint-disable max-len -->
<template>
  <div id="modalOnboardingApp" class="c-modal modal">
    <div class="c-section--content-title-before">
      <h2 class="text-center">Express</h2>
    </div>

    <div class="c-modal--img text-center">
      <slider-onboarding-express />
    </div>

    <div class="c-nav c-content--button-skip">
      <button
        class="c-button c-button--primary c-button--width-fuid c-button--flat c-button--mobile-small waves-effect c-modal--button modal-close"
      >
        Omitir
      </button>
    </div>
  </div>
</template>

<script>
import SliderOnboardingExpress from "../Express/SliderOnboardingExpress.vue";

export default {
  components: { SliderOnboardingExpress },
  name: "ModalExpressSlider",
};
</script>

<style></style>

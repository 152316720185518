<template>
  <div class="modal c-modal" :id="id">
    <div class="modal-container" style="display: flex">
      <div class="modal-form">
        <div class="c-modal--directions-title">
          <h2>Mi dirección</h2>
        </div>
        <div class="c-modal--directions-body">
          <div class="c-input input-field">
            <input type="text" v-model="place" />
            <label for="">Lugar</label>
          </div>
        </div>
        <div class="c-modal--directions-body">
          <div class="c-input input-field">
            <input
              ref="address"
              type="text"
              v-model="newAddress.description"
              :disabled="disableAddressInput === 'true'"
            />

            <label for="">Dirección</label>
          </div>
        </div>
        <div class="c-modal--directions-body">
          <div class="c-input input-field">
            <input type="text" v-model="specialInstructions" />
            <label for="">Instrucciones especiales</label>
          </div>
        </div>
        <div class="c-modal__footer-buttons c-nav m-1">
          <button
            class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
            v-on:click="save"
          >
            Guardar favorito
          </button>
          <button
            v-if="disableCloseButton === 'false'"
            class="c-button c-button--width-fuid c-button--secondary c-button--mobile-small waves-effect modal-close"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalAddress",
  props: {
    id: String,
    disableCloseButton: {
      type: String,
      default: "true",
    },
    disableAddressInput: {
      type: String,
      default: "true",
    },
    adress: {
      default: null,
    },
  },
  data() {
    return {
      place: "",
      specialInstructions: "",
    };
  },
  watch: {
    adress() {
      const newVal = this.adress;
      console.log(newVal);
      // this.newAddress.description = newVal.address;
      if (newVal !== null) {
        console.log(newVal);
        this.place = newVal.name;
        this.newAddress.description = newVal.address;
        this.newAddress.lat = newVal.location.Lat;
        this.newAddress.lng = newVal.location.Lng;
        this.specialInstructions = newVal.reference;
        this.$refs.address.update(newVal.address);
      } else {
        console.log("elese");
        this.resetInputs();
      }
    },
  },
  computed: {
    newAddress() {
      return this.$store.getters.ADD_ADDRESS;
    },
    isNewAdress() {
      return !this.adress;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.address.focus();
      // this.$refs.address.update(this.newAddress.description);
      this.$eventBus.$on("updateAddressInput", (addressInput) => {
        this.updateAddressInput(addressInput);
      });
    });
  },
  methods: {
    updateAddressInput(addressDescription) {
      this.$refs.address.update(addressDescription);
    },
    save() {
      const objectToSave = {
        ...(!this.isNewAdress
          ? { FavoriteLocationId: this.adress.favoriteLocationId }
          : {}),
        Address: this.newAddress.description,
        Latitud: this.newAddress.lat,
        Longitud: this.newAddress.lng,
        Name: this.place,
        Details: this.specialInstructions,
      };

      if (!this.place) {
        this.showError("Por favor, ingrese un nombre para la dirección.");
      } else if (
        !this.newAddress.description ||
        !this.newAddress.lat ||
        !this.newAddress.lng
      ) {
        this.showError(
          "Por favor ingrese una dirección válida de la lista desplegable."
        );
        return;
      }
      if (this.isNewAdress) {
        this.showLoading("Guardando direccion...");
        this.$store
          .dispatch("POST_FAVORITE_ADDRESS", objectToSave)
          .then((success) => {
            this.$swal.close();
            if (success.status === 200) {
              this.showOk("Contacto guardado exitosamente!");
              this.updateFavoriteLocations();
              this.closeModal(this.id);
              this.resetInputs();
            } else {
              this.showError(success.data.message);
            }
          })
          .catch((error) => {
            const msg = error?.data?.message || "Ocurrio un error.";
            this.showError(msg);
          });
      } else {
        this.$store
          .dispatch("PUT_FAVORITE_ADDRESS", objectToSave)
          .then((success) => {
            this.$swal.close();
            if (success.status === 200) {
              this.showOk("Contacto guardado exitosamente!");
              this.updateFavoriteLocations();
              this.closeModal(this.id);
              this.resetInputs();
            } else {
              this.showError(success.data.message);
            }
          })
          .catch((error) => {
            const msg = error?.data?.message || "Ocurrio un error.";
            this.showError(msg);
          });
      }
    },
    updateFavoriteLocations() {
      this.showLoading("Actualizando direcciones favoritas...");
      this.$store
        .dispatch("GET_FAVORITE_LOCATIONS")
        .then((success) => {
          if (!success) {
            this.showError("Por favor, vuelva a iniciar sesion.");
          } else {
            this.$swal.close();
          }
        })
        .catch(() => {
          this.showError("Por favor, vuelva a iniciar sesion.");
        });
    },
    resetInputs() {
      console.log(
        "🚀 ~ file: ModalAddress.vue:216 ~ resetInputs ~ resetInputs"
      );
      this.newAddress.description = "";
      this.newAddress.lat = 0;
      this.newAddress.lng = 0;
      this.specialInstructions = "";
      this.place = "";
      this.updateAddressInput(null);
    },
  },
};
</script>

<style scoped>
.modal {
  width: auto !important;
  max-width: 500px !important;
}
.modal-container {
  flex-direction: column;
  height: 100vh;
}
.map-container {
  width: -webkit-fill-available;
}
@media (max-width: 768px) {
  .map-container {
    height: -webkit-fill-available;
  }
}
@media (min-width: 768px) {
  .modal-container {
    flex-direction: row !important;
    height: auto;
  }
  .modal .modal-form {
    width: 800px;
  }
}
</style>

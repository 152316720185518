<!-- eslint-disable max-len -->
<template>
  <div style="display: flex; flex-direction: column">
    <div class="modal c-modal" :id="id">
      <div>
        <div class="c-modal--directions-title">
          <h2>Mis contactos</h2>
        </div>
        <div class="c-grid c-section--content-favorite">
          <div class="c-grid--service">
            <div class="c-section--content-article">
              <article class="c-grid--services__item">
                <div class="c-section--content-date">
                  <div class="c-section--content-date-options"></div>
                </div>
                <div class="c-content--modal__title">
                  <h2>Favoritos</h2>
                  <br />
                </div>
                <div
                  class="c-collection pb-1 collection d-flex m-0 collection-option text-left justify-content-start align-items-center"
                  v-for="favoriteContact in contacts"
                  :key="favoriteContact.contactId"
                >
                  <div
                    class="collection-item collection-item__line w-100 border-none"
                    :class="{
                      active: checkedContactId === favoriteContact.contactId,
                    }"
                  >
                    <label class="c-radio-buttons c-rb-item-type-doc">
                      <input
                        name="option-type-doc"
                        type="radio"
                        v-model="checkedContactId"
                        v-bind:value="favoriteContact.contactId"
                        @change="selectingContact(favoriteContact)"
                      /><span
                        class="font-weight-bold flex-column collection-circle"
                        >{{ favoriteContact.nombreContacto
                        }}<br class="font-weight-light" />N°
                        {{ favoriteContact.telefonoContacto }}</span
                      >
                    </label>
                  </div>
                  <div>
                    <unicon
                      @click="openViewContact(favoriteContact)"
                      name="eye"
                      fill="black"
                      style="
                        margin-right: 10px;
                        padding: 10px 0px;
                        cursor: pointer;
                        display: flex;
                      "
                      width="40"
                    />
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="c-content--buttond-add d-flex justify-content-center">
          <a
            class="d-flex align-items-center color-black font-weight-ligth p-1 c-content--box-shadow curso-pointer"
            id="c-btn-contact-form-1"
            @click="createNewOne"
            ><img
              class="mr-1"
              src="~@/assets/img/icon/icon-add.svg"
              alt="Agregar"
              width="34"
              height="34"
            />Agregar uno nuevo</a
          >
        </div>
        <div class="c-modal__footer-buttons c-nav m-1">
          <button
            class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
            id="c-btn-show-contact-1"
            @click="sendContact"
          >
            Seleccionar
          </button>
        </div>
        <div class="c-modal__footer-buttons c-nav m-1">
          <button
            class="c-button c-button--secondary c-button--width-fuid c-button--mobile-small waves-effect"
            id="c-btn-show-contact-1"
            @click="closeModal"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
    <modal-contact
      id="modal-contact-add"
      @getAddContactData="getNewContact"
      @saveChangesContact="saveChangesContact"
      :contact="viewContact"
      :disableFavoriteSwitch="viewContact ? 'true' : 'false'"
    />
  </div>
</template>

<script>
import M from "materialize-css";
import ModalContact from "./ModalContact.vue";

export default {
  components: { ModalContact },

  name: "ModalFavoriteContacts",
  props: {
    id: String,
  },
  data() {
    return {
      favoriteContacts: null,
      checkedContactId: 0,
      selectedContact: null,
      viewContact: null,
    };
  },
  methods: {
    closeModal() {
      const modalContactFavorite = document.querySelector("#modal-contact");
      if (modalContactFavorite) {
        const instanceModalContactFavorite =
          M.Modal.getInstance(modalContactFavorite);
        instanceModalContactFavorite.close();
      }
    },
    selectingContact(favoriteContact) {
      this.selectedContact = favoriteContact;
    },
    sendContact() {
      if (!this.selectedContact) {
        this.showError("Por favor, seleccione un contacto.");
      } else {
        this.$emit("getFavoriteContact", this.selectedContact);
        this.closeModal(this.id);
      }
    },
    createNewOne() {
      this.$eventBus.$emit("modalContactClearData");
      this.viewContact = null;
      this.openModal("modal-contact-add");
    },
    openViewContact(contact) {
      this.viewContact = contact;
      this.openModal("modal-contact-add");
    },
    getNewContact(newContact) {
      if (newContact.favorite === true) {
        const payload = {
          NombreContacto: newContact.nombreContacto,
          TelefonoContacto: newContact.telefonoContacto,
          CorreoContacto: newContact.correoContacto,
          NroDocumentoContacto: newContact.nroDocumentoContacto,
          DocumentType: newContact.documentType,
        };

        this.showLoading("Guardando Contacto...");
        this.$store
          .dispatch("POST_CONTACTS", payload)
          .then((success) => {
            this.$swal.close();
            if (success.status === 200) {
              //
            } else {
              this.showError(success.data.message);
            }
          })
          .catch((error) => {
            const msg = error?.data?.message || "Ocurrio un error.";
            this.showError(msg);
          });
      }
      this.closeModal(this.id);
      this.$emit("getFavoriteContact", newContact);
    },
    async saveChangesContact(contact) {
      const payload = {
        ContactId: contact.contactId,
        NombreContacto: contact.nombreContacto,
        TelefonoContacto: contact.telefonoContacto,
        CorreoContacto: contact.correoContacto,
        NroDocumentoContacto: contact.nroDocumentoContacto,
        DocumentType: contact.documentType,
      };
      this.showLoading("Guardando Contacto...");
      await this.$store
        .dispatch("PUT_CONTACTS", payload)
        .then((success) => {
          this.$swal.close();
          if (success.status !== 200) {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
      this.closeModal(this.id);
      this.$emit("getFavoriteContact", contact);
    },
  },
  mounted() {
    this.$nextTick(async () => {});
  },
  computed: {
    contacts() {
      return this.$store.getters.GENERAL_CONTACTS;
    },
  },
  watch: {
    contacts() {
      this.contacts[0].EsPredterminado = true;
      const predeterminado = this.contacts.find((item) => item.EsPredterminado);
      if (predeterminado) {
        this.selectedContact = predeterminado;
        this.checkedContactId = this.selectedContact.contactId;
      }
    },
  },
};
</script>

<style></style>
